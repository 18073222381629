<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="3" lg="3" class="mx-0 px-0">
						<!-- <v-text-field v-model="tableProps.search" label="Buscar un banco" clearable prepend-inner-icon="mdi-magnify" hide-details></v-text-field> -->
                        <alternativeTextField2
                            @onChanged="(v) => {trySearch(v);}"
                            @onClear="(v) => {trySearch(v);}"
                            :valueInput="tableProps.search"
                            :rule="[]"
                            :placeholder="'Buscar'"
                            :label="''"
                            :clearable="true"
                            :prependInnerIcon="'mdi-magnify'"
                            class="pick-3"
                        ></alternativeTextField2>
					</v-col>
					<v-spacer></v-spacer>
					<v-col v-permission="'outcomes.create'" cols="12" md="6" class="mx-0 ">
                        <v-row justify="end">
                            <iconButton :props="createBtnData"></iconButton>
                        </v-row>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<datatablePagination :options="options" :total="total"  :props="tableProps" @methodHandler="method_handler" @handleOptions="handleOptions">
                           
                        </datatablePagination>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

        <modal :props="modalProps" @modalResponse="method_handler">
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end">
					<v-col cols="12" sm="4">
						<secondaryButton
						:props="cancelModal"
						></secondaryButton>
					</v-col>
					<v-col cols="12" sm="4">
						<primaryButton
						:props="acceptModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>
		
	</div>
</template>
<script>
import PageStateManager from '../../helpers/PageStateManager';
import Utils from '../../helpers/Utils';
export default {
    data(){
		return {
            options: JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable'))??{},
            total: 10,
			search: '',
            selectedID: '',
			actions: [
                {
					name: 'PDF',
					icon: {
						color: 'secondary',
						icon: 'mdi-printer-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'pdf',
                    permissions: 'outcomes.show',
                    loading: false
				},
				{
					name: 'Detalle',
					icon: {
						color: 'secondary',
						icon: 'mdi-information-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'outcomes.show'
				},
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/obras/1/edicion',
                    permissions: 'outcomes.update'
				},
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItemModal',
                    permissions: 'outcomes.delete'
				},
			],
			tableProps: {
				headers: [
					{
						text: 'Folio',
						align: 'left break-words',
						value: 'folio_int',
						width: "18%",
						class: 'table-b-border black--text'
					},
                    {
						text: 'Fecha',
						align: 'left',
						value: 'date',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Proveedor',
						align: 'left',
						value: 'provider_name',
                        sort: true,
						class: 'table-b-border black--text'
					},
                    {
						text: 'monto',
						align: 'right',
                        
						value: 'amount',
						class: 'table-b-border black--text'
					},
					{
						text: 'ACCIONES',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: (JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable')))?.search??"",
				loading: false,
                sortBy: 'created_at',
                sortDesc: true,
			},
			
			
            createBtnData: {
                hasTooltip:true,
                tooltip: 'Nuevo pago',
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-plus",
				to: "OutcomeCreate",
				block: false,
                click: ()=>{}
            },

			modalProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},
			
			cancelModal: {
                text: "No, cancelar",
                icon: "",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.modalProps.visible = false}
            },
			acceptModal: {
                text: "SI, ELIMINAR",
                icon: "",
				to: "",
				block: true,
				color: 'error',
				textColor:'white',
                textColorLevel: '0',
				loading:false,
                click: ()=>{this.delete()}
            },
            
		}
	},

	mounted(){
      
		//this.index();
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
	},

    methods: {
        onResize () {
            
            if(window.innerWidth <= 960){
                this.createBtnData.block = true;
                
            }
            else{
                
                this.createBtnData.block = false;
            }
        },
        method_handler(object){
           
			this[object.methodName](object.parameters)
		},
        handleOptions(object){
            object.options[`search`] = this.tableProps.search;
			this.options = object.options;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options))
			this.index();
		},
        trySearch(val){
            this.tableProps.search = val;
            this.options.search = val;
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options))
            this.index();
        },
		index() {
			this.tableProps.loading = true;
			let filter = Utils.createFilter(this.options, this.tableProps.headers);
			//call api
			this.$api.outcome.list(this.$store.state.business, filter)
				.then((resp) =>{
					//console.log(resp.data);
					this.tableProps.items = resp.data.data.map((x, index) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actions))
                       
						x.actions[1].action = { name: 'OutcomeDetails', params: { slug: x.folio } }
						x.actions[2].action = { name: 'OutcomeEdit', params: { slug: x.folio } }
                       
						

						x['parameters'] = { id: x.folio, name: x.folio_int, index: index, folioIn: x.folio_int}
                        let original = JSON.parse(JSON.stringify(x));
						//x.status = x.status == true ? 'Activo' : 'Inactivo';
                        x.date = Utils.globalDateFormat(x.date);
                        x.amount = Utils.currencyFormat(original.amount, (x.currency??''));
                        
						return x
					});
                    this.total = resp.data.total
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},

		deleteItemModal(parameters)
		{
			//console.log(parameters);
			this.selectedID = parameters.id;
			this.modalProps = {
				...this.modalProps,
				text: `¿Estás seguro de eliminar "${parameters.name}"?`,
				
			}
            console.log(this.selectedID)
			this.modalProps.visible = true;
			
		},


		delete()
		{
			//console.log(this.selectedID);
			this.acceptModal.loading = true;
			this.$api.outcome.delete(this.$store.state.business,this.selectedID)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', `Se ha eliminado`);
				this.index()
				// eslint-disable-next-line
			}).catch(error => {
				if(error.response.status === 422){
                    this.$store.dispatch('snackbarError', `${error.response.data.error??'Error al eliminar'}`);
                }else{
                    this.$store.dispatch('snackbarError', `Error al eliminar. Intenta de nuevo`);
                }

				
			})
			.finally(() => {
				this.modalProps.visible = false;
				this.acceptModal.loading = false;
			})
		},

        pdf(params)
        {

            console.log(params);
            this.tableProps.items[params.index].actions[0].loading = true;
            this.$api.outcome.pdfDetails(this.$store.state.business, params.id)
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `${params.folioIn}.pdf`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.tableProps.items[params.index].actions[0].loading = false;
                })
            ;
        }

    }
}
</script>