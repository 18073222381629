<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="3" lg="3" class="mx-0 px-0">
						<!-- <v-text-field v-model="tableProps.search" label="Buscar un banco" clearable prepend-inner-icon="mdi-magnify" hide-details></v-text-field> -->
                        <alternativeTextField2
                            @onChanged="(v) => {trySearch(v);}" 
                            :valueInput="tableProps.search"
                            :rule="[]"
                            :placeholder="'Buscar'"
                            :label="''"
                            
                            :prependInnerIcon="'mdi-magnify'"
                            class="pick-3"
                        ></alternativeTextField2>
					</v-col>
					<v-spacer></v-spacer>
					<v-col   md="5" class="mx-0">
                        <v-row justify="end">
                            <iconButton :props="createBtnData" v-permission="'constructions.create'"></iconButton>
                        </v-row>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<datatablePagination :options="options" :total="total"  :props="tableProps" @methodHandler="method_handler" @handleOptions="handleOptions">
                           
                        </datatablePagination>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

        <modal :props="modalProps" @modalResponse="method_handler">
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end">
					<v-col cols="12" sm="4">
						<secondaryButton
						:props="cancelModal"
						></secondaryButton>
					</v-col>
					<v-col cols="12" sm="4">
						<primaryButton
						:props="acceptModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>
		
	</div>
</template>
<script>
import Utils from '../../../helpers/Utils';
export default {
    data(){
		return {
            options: {},
            total: 10,
			search: '',
            selectedID: '',
			actions: [
                
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/obras/1/edicion',
                    permissions: 'constructions.update'
				},
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItemModal',
                    permissions: 'constructions.delete'
				},
			],
			tableProps: {
				headers: [
					{
						text: 'Nombre',
						align: 'left',
						value: 'name',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Estatus',
						align: 'left',
						value: 'status',
                        width: '9%',
						class: 'table-b-border black--text'
					},
                    
					{
						text: 'ACCIONES',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false,
                sortBy: 'name',
                sortDesc: false,
			},
			
			
            createBtnData: {
                hasTooltip:true,
                tooltip: 'Nueva obra',
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-plus",
				to: "ConstructionsCreate",
				block: false,
                click: ()=>{}
            },
           

			modalProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},
			
			cancelModal: {
                text: "No, cancelar",
                icon: "",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.modalProps.visible = false}
            },
			acceptModal: {
                text: "SI, ELIMINAR",
                icon: "",
				to: "",
				block: true,
				color: 'error',
				textColor:'white',
                textColorLevel: '0',
				loading:false,
                click: ()=>{this.delete()}
            },
		}
	},

	mounted(){
		//this.index()
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
	},

    methods: {
        onResize () {
            
            if(window.innerWidth < 960){
                this.createBtnData.block = true;
            }
            else{
                this.createBtnData.block = false;
            }
        },
        method_handler(object){
            console.log(object);
			this[object.methodName](object.parameters)
		},
        handleOptions(object){
            object.options[`search`] = this.tableProps.search;
			//console.log(object.options);
			this.options = object.options;
			this.index();
		},
        trySearch(val){
            this.tableProps.search = val;
            this.options.page = 1;
            this.index();
        },
		index() {
			this.tableProps.loading = true;
            this.options['search'] = this.tableProps.search;
			let filter = Utils.createFilter(this.options, this.tableProps.headers);
			//call api
			this.$api.construction.list(this.$store.state.business, filter)
				.then((resp) =>{
					//console.log(resp);
					this.tableProps.items = resp.data.data.map((x) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actions))

						x.actions[0].action = { name: 'ConstructionsEdit', params: { slug: x.slug } }
						

						x['parameters'] = { 
                            id: x.slug, 
                            name: x.name,
                           
                        };
                        x.status = x.status == 1 ? "Activo":"Inactivo";
						return x;
					});
                    this.total = resp.data.total
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},

       
		deleteItemModal(parameters)
		{
			//console.log(parameters);
			this.acceptModal.text = "SI, ELIMINAR";
            this.cancelModal.text = "No, cancelar";
            this.acceptModal.click = this.delete;
            this.acceptModal.color = 'error';
			this.selectedID = parameters.id;
			this.modalProps = {
				...this.modalProps,
				text: `¿Estás a punto de eliminar ¿deseas continuar?`,
				title: 'Eliminar',
                bottomLineColor: 'error',
			}
            //console.log(this.selectedID)
			this.modalProps.visible = true;
			
		},


		delete()
		{
			//console.log(this.selectedID);
			this.acceptModal.loading = true;
			this.$api.construction.delete(this.$store.state.business,this.selectedID)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', `Se ha eliminado`);
				this.index();
                this.$store.dispatch('removeSideitem', this.selectedID);
				// eslint-disable-next-line
			}).catch(error => {
				if(error.response.status === 422){
                    this.$store.dispatch('snackbarError', `${error.response.data.error??'Error al eliminar'}`);
                }else{
                    this.$store.dispatch('snackbarError', `Error al eliminar. Intenta de nuevo`);
                }

				
			})
			.finally(() => {
				this.modalProps.visible = false;
				this.acceptModal.loading = false;
			})
		},

        

    }
}
</script>