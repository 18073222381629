<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="5" lg="5" class="mx-0 px-0 pt-0">
						<!-- <v-text-field v-model="tableProps.search" label="Buscar un banco" clearable prepend-inner-icon="mdi-magnify" hide-details></v-text-field> -->
                        <h3 class="customBlue--text">Anticipos - {{folio}}</h3>
                        <h3 class="grey-darken1--text">Monto de la factura de anticipo: <span class="customBlue--text">{{total}}</span></h3>
                        <h3 class="grey-darken1--text">Monto efectuado: <span class="customBlue--text">{{balance}}</span></h3>
                        <h3 class="grey-darken1--text">Monto por efectuar: <span class="customBlue--text">{{pending}}</span></h3>
					</v-col>
					<v-spacer></v-spacer>
					<v-col cols="12" md="5" lg="5" class="mx-0 " align-self="start">
                       <v-row justify="end">
                            <iconButton class="mr-0 mr-md-5 mb-2 mb-md-0" :props="cancelBtnData"></iconButton>
                            <iconButton v-permission="'payable_accounts.create'" :props="createBtnData"></iconButton>
                       </v-row>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0" >
						<datatable :options="options" :props="tableProps" @methodHandler="methodHandler" @handleOptions="handleOptions">
                           
                        </datatable>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

        <modal :props="modalProps" @modalResponse="methodHandler">
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end">
					<v-col cols="12" sm="4">
						<secondaryButton
						:props="cancelModal"
						></secondaryButton>
					</v-col>
					<v-col cols="12" sm="4">
						<primaryButton
						:props="acceptModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>
		
	</div>
</template>
<script>
import PageStateManager from '../../../helpers/PageStateManager';
import Utils from '../../../helpers/Utils';
export default {
    computed: {
        folio: function(){
            console.log(this.cxp);
            if(this.cxp !== null)
                return `${this.cxp.payable_account.folio}`;
            return '';
        },
        total: function(){
            if(this.cxp !== null)
                return `${Utils.currencyFormat(this.cxp.total,'')}`;
            return '';
        },
        balance: function(){
            if(this.cxp !== null)
                return `${Utils.currencyFormat(this.cxp.balance, '')}`;
            return '';
        },
        pending: function(){
            if(this.cxp !== null)
                return `${Utils.currencyFormat(this.cxp.pending, '')}`;
            return '';
        }
       
    },
    data(){
		return {
            slug: this.$route.params.slug,
            options: (JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable')))??{},
			search: '',
            selectedID: '',
            selectedAcxp: '',
            cxp: null,
			actions: [
				{
					name: 'Detalle',
					icon: {
						color: 'secondary',
						icon: 'mdi-information-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'payable_accounts.show'
				},
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'payable_accounts.update'
				},
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItemModal',
                    permissions: 'payable_accounts.delete'
				},
			],
			tableProps: {
				headers: [
					{
						text: 'Folio',
						align: 'left',
						value: 'folio',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Vigencia',
						align: 'left',
						value: 'due_date',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Cuenta',
						align: 'left',
						value: 'concept.name',
                        
						class: 'table-b-border black--text'
					},
                    {
						text: 'MONTO',
						align: 'left',
                        
						value: 'total',
						class: 'table-b-border black--text'
					},
					{
						text: 'ACCIONES',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
                
				search: (JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable')))?.search??"",
				loading: true
			},
			
			
            createBtnData: {
                hasTooltip:true,
                tooltip: 'Nuevo anticipo',
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-plus",
				to: "AdvancedCXPCreate",
				block: false,
                click: ()=>{}
            },
            cancelBtnData: {
                outlined:true,
                hasTooltip:true,
                tooltip: 'Regresar',
                color:'black',
                iconColor:'grey darken-3',
                height:'35',
                iconClass:"transparentBackground",
                icon: "mdi-close",
                to: "CXP",
                block: false,
                click: () => {}
            },


			modalProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},
			
			cancelModal: {
                text: "No, cancelar",
                icon: "",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.modalProps.visible = false}
            },
			acceptModal: {
                text: "SI, ELIMINAR",
                icon: "",
				to: "",
				block: true,
				color: 'error',
				textColor:'white',
                textColorLevel: '0',
				loading:false,
                click: ()=>{this.delete()}
            },
		}
	},

	mounted(){
		this.index();
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
	},

    methods: {
        onResize () {
            
            if(window.innerWidth <= 960){
                this.createBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.cancelBtnData.block = false;
                this.createBtnData.block = false;
            }
        },
        methodHandler(object){
            console.log(object);
			this[object.methodName](object.parameters)
		},
        handleOptions(object){
            object.options[`search`] = this.tableProps.search;
			console.log(object.options, "ioptions");
            // if(!this.tableProps.loading)
            this.options = object.options;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options))
			// this.index();
		},
       
		index() {
			this.tableProps.loading = true;
            this.options['search'] = this.tableProps.search;
			
			// call api
			this.$api.payableAccount.advanceList(this.$store.state.business, this.slug)
				.then((resp) =>{
					//console.log(resp)
                    this.cxp = resp.data;
					this.tableProps.items = resp.data.list.map((x) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actions))
						x.actions[0].action = { name: 'AdvancedCXPDetails', params: { slug: this.slug, acxp: x.slug } }
						x.actions[1].action = { name: 'AdvancedCXPEdit', params: { slug: this.slug ,acxp: x.slug } }
                       // x.actions[2].action = { name: '', params: { slug: x.slug } }
						
                        
						x['parameters'] = { slug: this.slug, acxp: x.slug}
                        let original = JSON.parse(JSON.stringify(x));
						// //x.status = x.status == true ? 'Activo' : 'Inactivo';
                        x.due_date = Utils.globalDateFormat(x.due_date);
                        x.total = Utils.currencyFormat(original.total, x.currency);
                        // x.total = Utils.currencyFormat((original.total - original.balance), x.currency);
						return x
					});
                    
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},

		deleteItemModal(parameters)
		{
			console.log(parameters);
			this.selectedID = parameters.slug;
            this.selectedAcxp = parameters.acxp;
			this.modalProps = {
				...this.modalProps,
				text: `¿Estás seguro de eliminar "${parameters.acxp}"?`,
				
			}
            console.log(this.selectedID)
			this.modalProps.visible = true;
			
		},


		delete()
		{
			//console.log(this.selectedID);
			this.acceptModal.loading = true;
			this.$api.payableAccount.advancedDelete(this.$store.state.business,this.selectedID, this.selectedAcxp)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', `Se ha eliminado`);
				this.index()
				// eslint-disable-next-line
			}).catch(error => {
				if(error.response.status === 422){
                    this.$store.dispatch('snackbarError', `${error.response.data.error??'Error al eliminar'}`);
                }else{
                    this.$store.dispatch('snackbarError', `Error al eliminar. Intenta de nuevo`);
                }

				
			})
			.finally(() => {
				this.modalProps.visible = false;
				this.acceptModal.loading = false;
			})
		},

    }
}
</script>