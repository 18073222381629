var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"py-2"},[_c('fileInputForm',{attrs:{"accept":".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel","label":"Estado de cuenta en Excel","multiple":false,"valueInput":_vm.values.excel_file,"url":_vm.values.excel_file_url,"rule":[],"errorMessages":_vm.serverErrors.excel_file},on:{"valueChange":(v) => {
                        _vm.values.excel_file = v;
                        delete _vm.serverErrors.file;
                        delete _vm.serverErrors.excel_file;
                    },"update:url":function($event){_vm.values.excel_file_url = $event}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-2"},[_c('fileInputForm',{attrs:{"accept":'.pdf,.PDF',"label":"Estado de cuenta en PDF","multiple":false,"valueInput":_vm.values.pdf_file,"url":_vm.values.pdf_file_url,"rule":[],"errorMessages":_vm.serverErrors.pdf_file},on:{"valueChange":(v) => {
                        _vm.values.pdf_file = v;
                        delete _vm.serverErrors.file;
                        delete _vm.serverErrors.pdf_file;
                    },"update:url":function($event){_vm.values.pdf_file_url = $event}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }