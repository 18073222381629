
<template>
    <div>
        <v-row>
            <v-col class="py-2">
                <fileInputForm
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    label="Estado de cuenta en Excel"
                    :multiple="false"
                    :valueInput="values.excel_file"
                    :url="values.excel_file_url"
                    :rule="[]"
                    @valueChange="
                        (v) => {
                            values.excel_file = v;
                            delete serverErrors.file;
                            delete serverErrors.excel_file;
                        }
                    "
                    :errorMessages="serverErrors.excel_file"
                    v-on:update:url="values.excel_file_url = $event"
                ></fileInputForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="py-2">
                <fileInputForm
                    :accept="'.pdf,.PDF'"
                    label="Estado de cuenta en PDF"
                    :multiple="false"
                    :valueInput="values.pdf_file"
                    :url="values.pdf_file_url"
                    :rule="[]"
                    @valueChange="
                        (v) => {
                            values.pdf_file = v;
                            delete serverErrors.file;
                            delete serverErrors.pdf_file;
                        }
                    "
                    :errorMessages="serverErrors.pdf_file"
                    v-on:update:url="values.pdf_file_url = $event"
                ></fileInputForm>
            </v-col>
        </v-row>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    props: ["values", "serverErrors"],

    data() {
        return {
            rules: {
				
                file_required: [
                    v => {
                        if(v instanceof Array || v == "" || v == null)
                            return 'El archivo es requerido';
                        
                        return true;
                    }
                ],
            },
        };
    },

    mounted() {},
    methods: {},
};
</script>

<style>
</style>