<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center">
                    <v-col class="text-center col-md-6 col-lg-5">
                        <div class="font-weight-bold body-1">
                            Archivos del estado de cuenta de {{ monthName }}
                            {{ year }}
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="6" lg="5">
                        <v-form ref="form" @submit.prevent="save">
                            <RIS-form
                                ref="RISForm"
                                :values="form"
                                @save="save"
                                :serverErrors="serverErrors"
                            ></RIS-form>
                        </v-form>
                    </v-col>
                </v-row>
            </div>

            <v-row justify="center" align="end">
                <v-col cols="12" md="6" lg="5" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button
                            :props="cancelBtnData"
                            class="mr-0 mr-sm-2 mb-3 mb-sm-0"
                        ></secondary-button>
                        <primary-button
                            :props="saveBtnData"
                            class=""
                        ></primary-button>
                    </v-row>
                </v-col>
            </v-row>
        </template>
    </contentCard>
</template>

<script>
import Object2Form from "../../../helpers/Object2Form";
import Utils from "../../../helpers/Utils";
import RISForm from "./Form.vue";
export default {
    components: {
        "RIS-form": RISForm,
    },
    data() {
        return {
            bankAccount: this.$route.params.bankAccount,
            month: this.$route.params.month,
            year: this.$route.params.year,
            months: [
                "Enero",
                "Febrero",
                "Marzo",
                "Abril",
                "Mayo",
                "Junio",
                "Julio",
                "Agosto",
                "Septiembre",
                "Octubre",
                "Noviembre",
                "Diciembre",
            ],

            loading: false,
            serverErrors: {},
            form: {
                year: "",
                month: "",
                pdf_file: [],
                pdf_file_url: "",
                excel_file: [],
                excel_file_url: "",
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "BankAccountsReportIncomeStatements",
                block: false,
                click: () => {},
            },
            saveBtnData: {
                text: "Guardar",
                icon: "mdi-content-save-outline",
                click: this.save,
                block: false,
                loading: false,
            },
            snackbarProps: {
                visible: false,
                color: "success",
                timeout: 3000,
                text: "",
            },
        };
    },
    computed: {
        monthName: function () {
            if (this.cxp !== null) return `${this.months[this.month - 1]}`;
            return "";
        },
    },
    mounted() {
        this.index();
        window.addEventListener("resize", this.onResize, { passive: true });
        this.onResize();
    },
    methods: {
        onResize() {
            if (window.innerWidth < 600) {
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            } else {
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
        validate() {
            this.$refs.form.validate();
            if (!Utils.objectIsEmpty(this.serverErrors)) return false;

            return this.$refs.form.validate();
            // return true;
        },
        validateFile(v) {
            if (v instanceof Array || v == "" || v == null) return false;

            return true;
        },
        index() {
            this.loading = true;
            this.form.year = this.year;
            this.form.month = this.month;

            this.$api.report
                .reportIncomeStatements(this.$store.state.business, this.bankAccount, this.year)
                .then((resp) => {
                    const found = resp.data.find((x) => x.month == this.month);
                    this.form.excel_file_url = found?.excel_file_url ?? "";
                    this.form.pdf_file_url = found?.pdf_file_url ?? "";
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.$store.state.loading = false;
                });
        },
        save() {
            //console.log(this.form);

            if (this.validate()) {
                let form = this.prepareRequest(this.form);
                this.saveBtnData.loading = true;
                this.$store.state.loading = true;
                this.$api.report
                    .saveReportIncomeStatements(
                        this.$store.state.business,
                        this.bankAccount,
                        form
                    )
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch(
                            "snackbarSuccess",
                            `Se ha actualizado correctamente`
                        );
                        this.$router.push({ name: "BankAccountsReportIncomeStatements" });
                    })
                    .catch((error) => {
                        var errors = [];
                        switch (error.response.status) {
                            case 422:
                                if ("error" in error.response.data) {
                                    if (
                                        error.response.data.error instanceof
                                        Object
                                    ) {
                                        Object.keys(
                                            error.response.data.error
                                        ).forEach((x) => {
                                            console.log(x);
                                            this.serverErrors =
                                                error.response.data.error;
                                            error.response.data.error[
                                                x
                                            ].forEach((y) => {
                                                this.serverErrors[x] =
                                                    Utils.deleteWord(x, y);
                                                errors.push(
                                                    Utils.deleteWord(x, y)
                                                );
                                            });
                                        });
                                    } else {
                                        errors.push(error.response.data.error);
                                    }

                                    this.$store.dispatch(
                                        "snackbarError",
                                        errors.join(".<br>")
                                    );
                                }

                                break;
                        }
                        console.log(this.serverErrors);
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    });
            } else {
                this.$store.dispatch("snackbarError", `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        },

        prepareRequest(request) {
            let data = JSON.parse(JSON.stringify(request));

            data.pdf_file = request.pdf_file;
            data.excel_file = request.excel_file;

            if (request.pdf_file_url == "" && request.pdf_file instanceof Array)
                data.pdf_file = null;

            if (
                request.excel_file_url == "" &&
                request.excel_file instanceof Array
            )
                data.excel_file = null;

            return Object2Form.objectToFormData(data, "", []);
        },
    },
};
</script>

<style>
</style>