<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="3" lg="3" class="mx-0 px-0">
						<!-- <v-text-field v-model="tableProps.search" label="Buscar un banco" clearable prepend-inner-icon="mdi-magnify" hide-details></v-text-field> -->
                        <alternativeTextField2
                            @onChanged="(v) => {trySearch(v);}"
                            @onClear="(v) => {trySearch(v);}"
                            :valueInput="tableProps.search"
                            :rule="[]"
                            :placeholder="'Buscar'"
                            :label="''"
                            :clearable="true"
                            :prependInnerIcon="'mdi-magnify'"
                            class="pick-3"
                        ></alternativeTextField2>
					</v-col>
					<v-spacer></v-spacer>
					<v-col v-permission="'users.create'" cols="12" md="3" lg="3" xl="2" class="mx-0" >
                        <v-row justify="end"> 
                            <iconButton :props="createBtnData"></iconButton>
                        </v-row>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0" >
						<datatable :options="options" :props="tableProps" @methodHandler="method_handler"  @handleOptions="handleOptions">
                           
                        </datatable>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

        <!-- PASSWORD CHANGE MODAL -->
        <modal :props="modalPasswordProps" @modalResponse="method_handler">
            <!-- FORM -->
            <template slot="cardBody">
				<v-form ref="form" @submit.prevent="save">
                    <v-row>
                        <v-col class="py-2">
                            <textFieldForm 
                                @valueChange="(v) => {password = v; delete serverErrors.password}" 
                                :valueInput="password" 
                                :rule="[...rules.required, ...rules.length8]" 
                                label="Contraseña"
                                :errorMessages="serverErrors.password"
                                :count="true"
                                :prependInnerIcon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showPass ? 'text' : 'password'"
                                @clickAppend="() => {showPass = !showPass}"
                            ></textFieldForm>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-2">
                            <textFieldForm 
                                @valueChange="(v) => {password_confirmation = v; delete serverErrors.password_confirmation}" 
                                :valueInput="password_confirmation" 
                                :rule="[...rules.required, ...rules.samePassword, ...rules.length8]" 
                                label="Confirmar contraseña"
                                :errorMessages="serverErrors.password_confirmation"
                                :count="true"
                                :type="showPass ? 'text' : 'password'"
                                @click:append="showPass = !showPass"
                            ></textFieldForm>
                        </v-col>
                    </v-row>
                </v-form>
			</template>
            <!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end">
					<v-col cols="12" sm="4">
						<secondaryButton
						:props="cancelPasswordModal"
						></secondaryButton>
					</v-col>
					<v-col cols="12" sm="4">
						<primaryButton
						:props="acceptPasswordModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>

        <!-- DELETE MODAL -->
        <modal :props="modalProps" @modalResponse="method_handler">
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end">
					<v-col cols="12" sm="4">
						<secondaryButton
						:props="cancelModal"
						></secondaryButton>
					</v-col>
					<v-col cols="12" sm="4">
						<primaryButton
						:props="acceptModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>
		
	</div>
</template>
<script>
import PageStateManager from '../../../helpers/PageStateManager';
import Utils from "../../../helpers/Utils";
export default {
    data(){
		return {
            options: JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable'))??{},
            password: '',
            password_confirmation: '',
            showPass: false,
            serverErrors:{},
			search: '',
            selectedID: '',
			actions: [
                {
					name: 'Cambiar contraseña',
					icon: {
						color: 'secondary',
						icon: 'mdi-lock-reset'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'changePasswordModal',
                    permissions: 'users.update'
				},
				{
					name: 'Detalle',
					icon: {
						color: 'secondary',
						icon: 'mdi-information-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'users.show'
				},
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'users.update'
				},
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItemModal',
                    permissions: 'users.delete'
				},
			],
			tableProps: {
				headers: [
					{
						text: 'Nombre',
						align: 'left',
						value: 'name',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Correo',
						align: 'left',
						value: 'email',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Rol',
						align: 'left',
						value: 'role.name',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Estatus',
						align: 'left',
						value: 'status',
                        
						class: 'table-b-border black--text'
					},
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '12%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: (JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable')))?.search??"",
				loading: true
			},
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                samePassword:[
                    (v) => v == this.password || "Las contraseñas no coinciden",
                ],
                length8:[
                    v => !!v || 'El campo requerido',
                    v => (!!v && v.length >= 8) || 'Al menos de 8 caracteres'
                ]
				
				
            },
			
			
            createBtnData: {
                 hasTooltip:true,
                tooltip: 'Nuevo usuario',
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-plus",
				to: "UsersCreate",
				block: false,
                click: ()=>{}
            },

			modalProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},
            modalPasswordProps:{
                visible: false,
				width: '500',
				title: 'Cambio de contraseña',
				text: '',
				loading: false,
				bottomLineColor: '',
                persistent:true,
            },

            cancelPasswordModal: {
                text: "Cancelar",
                icon: "",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.modalPasswordProps.visible = false; this.resetPassForm()}
            },
            acceptPasswordModal: {
                text: "Aceptar",
                icon: "",
				to: "",
				block: true,
				loading:false,
                click: ()=>{this.changePassword()}
            },
			
			cancelModal: {
                text: "No, cancelar",
                icon: "",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.modalProps.visible = false}
            },
			acceptModal: {
                text: "SI, ELIMINAR",
                icon: "",
				to: "",
				block: true,
				color: 'error',
				textColor:'white',
                textColorLevel: '0',
				loading:false,
                click: ()=>{this.delete()}
            },
		}
	},

	mounted(){
		this.index();
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
	},

    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        onResize () {
            
            if(window.innerWidth < 960){
                this.createBtnData.block = true;
            }
            else{
                this.createBtnData.block = false;
            }
        },
        validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
			return this.$refs.form.validate();
            // return true;
		},
      
        handleOptions(object){
            object.options[`search`] = this.tableProps.search;
			console.log(object.options, "optionss");
			this.options = object.options;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
			// this.index();
		},
        trySearch(val){
            this.tableProps.search = val;
            this.options.search = val;
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
            // this.index();
        },
		index() {
			this.tableProps.loading = true;
           
			//call api
			this.$api.user.list()
				.then((resp) =>{
					//console.log(resp);
					this.tableProps.items = resp.data.map((x) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actions))
                       
						
						// x.actions[0].action = { name: '', params: { slug: x.slug } }
                        x.actions[1].action = { name: 'UsersDetails', params: { slug: x.slug } }
						x.actions[2].action = { name: 'UsersEdit', params: { slug: x.slug } }

						x['parameters'] = { id: x.slug, name: x.name};
                        
						x.status = x.status === 1 ? 'Activo' : 'Inactivo';
                       
						return x;
					});
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},

        resetPassForm()
        {
            this.password = this.password_confirmation = '';
            this.$refs.form.reset();
        },
        changePasswordModal(parameters)
        {
            this.selectedID = parameters.id;
			this.modalPasswordProps = {
				...this.modalPasswordProps,
			}
            console.log(this.selectedID)
			this.modalPasswordProps.visible = true;
        },

        changePassword()
        {   
            if(this.validate())
            {
                this.modalPasswordProps.loading = true;
                this.$api.user.changePassword({'password':this.password, 'password_confirmation': this.password_confirmation}, this.selectedID)
                    .then(() => {
                        this.$store.dispatch('snackbarSuccess', `Se ha cambiado la contraseña`);
                        this.resetPassForm();
                        this.modalPasswordProps.visible = false;
                    })
                    .catch((error) => {
                        var errors = []
                        switch(error.response.status){
                            case 422: 
                                
                                if(("error" in error.response.data)){
                                    if(error.response.data.error instanceof Object){
                                        Object.keys(error.response.data.error).forEach((x) => {
                                            this.serverErrors = error.response.data.error;
                                            error.response.data.error[x].forEach((y) => {
                                                this.serverErrors[x] = Utils.deleteWord(x,y);
                                                errors.push(Utils.deleteWord(x,y))
                                            })
                                        });
                                    }else{
                                        errors.push(error.response.data.error)
                                    }
                                    
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }
                    })
                    .finally(() => {
                        this.modalPasswordProps.loading = false;
                        
                    })
                ;
            }
        },

		deleteItemModal(parameters)
		{
			//console.log(parameters);
			this.selectedID = parameters.id;
			this.modalProps = {
				...this.modalProps,
				text: `¿Estás seguro de eliminar "${parameters.name}"?`,
				
			}
            console.log(this.selectedID)
			this.modalProps.visible = true;
			
		},


		delete()
		{
			//console.log(this.selectedID);
			this.acceptModal.loading = true;
			this.$api.user.delete(this.selectedID)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', `Se ha eliminado`);
				this.index()
				// eslint-disable-next-line
			}).catch(error => {
				if(error.response.status === 422){
                    this.$store.dispatch('snackbarError', `${error.response.data.error??'Error al eliminar'}`);
                }else{
                    this.$store.dispatch('snackbarError', `Error al eliminar. Intenta de nuevo`);
                }

				
			})
			.finally(() => {
				this.modalProps.visible = false;
				this.acceptModal.loading = false;
			})
		},

    }
}
</script>