const headers = [
    {
        text: 'Todos',
        align: 'left',
        value: 'all',
        sortable: false,
        class: 'table-b-border  black--text'
    },
    {
        text: 'Crear',
        align: 'left',
        value: 'create',
        sortable: false,
        class: 'table-b-border  black--text'
    },
    {
        text: 'Ver',
        align: 'left',
        value: 'show',
        sortable: false,
        class: 'table-b-border  black--text'
    },
    {
        text: 'Editar',
        align: 'left',
        value: 'update',
        sortable: false,
        class: 'table-b-border  black--text'
    },
    {
        text: 'Borrar',
        align: 'left',
        value: 'delete',
        sortable: false,
        class: 'table-b-border  black--text'
    },
    
]

const modules = [
  // {label:"Root", submodules:[ {key:"root", label: "Administrador"} ]},
    {
        props: {
            headers: [
                {
                    text: 'General',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
            
        },
        label: "General",
        submodules: [
            
            { key: "dashboard", label: "Dashboard", notIn: ["update_menu", "all", "create", "update", "delete", "cancel"]  },
            { key: "invoices", label: "Invoices", notIn: ["update_menu", "cancel"]  },
            { key: "income_statement", label: "Estado de resultados", notIn: ["update_menu", "all", "create", "update", "delete", "cancel"]  }
        ],
    },

    
    {
        props: {
            headers: [
                {
                    text: 'Ingresos',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers,
                {
                    text: 'Cancelar',
                    align: 'left',
                    value: 'cancel',
                    sortable: false,
                    class: 'table-b-border  black--text'
                },
            ],
            
        },
        label: "Ingresos",
        submodules: [
            
            { key: "receivable_accounts", label: "Ventas", notIn: ["update_menu", ]  },
            { key: "incomes", label: "Cobros", notIn: ["update_menu", "cancel"]  },
            { key: "depositos", label: "Otros Ingresos", notIn: ["update_menu", "cancel"]  },
            { key: "income_credit_notes", label: "Notas de crédito", notIn: ["update_menu", "cancel"]  },
            { key: "income_loans", label: "Préstamos", notIn: ["update_menu", "cancel"]  },
        ],
    },

    {
        props: {
            headers: [
                {
                    text: 'Egresos',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers,
                {
                    text: 'Cancelar',
                    align: 'left',
                    value: 'cancel',
                    sortable: false,
                    class: 'table-b-border  black--text'
                },
            ],
            
        },
        label: "Egresos",
        submodules: [
            
            { key: "payable_accounts", label: "Compras", notIn: ["update_menu", ]  },
            { key: "outcomes", label: "Pagos", notIn: ["update_menu", "cancel"]  },
            { key: "retiros", label: "Otros gastos", notIn: ["update_menu", "cancel"]  },
            { key: "credit_notes", label: "Notas de crédito", notIn: ["update_menu", "cancel"]  },
            { key: "outcomes_loans", label: "Préstamos", notIn: ["update_menu", "cancel"]  }, //modify key
        ],
    },
    // {
    //     props: {
    //         headers: [
    //             {
    //                 text: 'Préstamos Inter',
    //                 align: 'left',
    //                 value: 'label',
    //                 sortable: false,
    //                 width:'40%',
    //                 class: 'table-b-border  black--text'
    //             },
    //             ...headers
    //         ],
           
    //     },
    //     label: "Préstamos Inter",
    //     submodules: [
    //         { key: "loans", label: "Préstamos Inter", notIn: ["update_menu", "cancel"]  },
    //     ],
    // },
    
    {
        props: {
            headers: [
                {
                    text: 'Agentes aduanales',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
           
        },
        label: "Agentes aduanales",
        submodules: [
           
            { key: "advances", label: "Anticipos", notIn: ["update_menu", "cancel"]  },
            { key: "demoras", label: "Demoras", notIn: ["update_menu", "cancel"]  },
            { key: "warranties", label: "Garantías", notIn: ["update_menu", "cancel"]  },
            { key: "ca_payable_accounts", label: "Cuentas de gasto", notIn: ["update_menu", "cancel"]  },
            { key: "payments", label: "Pagos", notIn: ["update_menu", "cancel"]  },
            { key: "pece_accounts", label: "Cuenta PECE", notIn: ["update_menu", "cancel"]  },
            { key: "extraordinaries", label: "Extraordinarios", notIn: ["update_menu", "cancel"]  },
            { key: "ca_positive_balances", label: "Saldos a favor", notIn: ["update_menu", "cancel"]  },
            { key: "customs_agents", label: "Agentes aduanales",notIn: ["update_menu", "cancel"] }
            
            
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Obras',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers,
                
            ],
           
        },
        label: "Obras",
        submodules: [
            { key: "constructions", label: "Obras", notIn: ["update_menu", "cancel"]  },
            { key: "construction-types", label: "Tipos de obra", notIn: ["update_menu", "cancel"]  },
            { key: "reports_contractors_cxc", label: "Ingreso x obra", notIn:["update_menu", "all", "create", "update", "delete", "cancel"]},
            { key: "contractors_balance", label: "Saldo x obra", notIn: ["update_menu", "all", "create", "update", "delete", "cancel"]},
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Obras - Egresos',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
           
        },
        label: "Obras - Egresos",
        submodules: [
           
            { key: "contractor_payable_accounts", label: "Contratos", notIn: ["update_menu", "cancel"]  },
            { key: "contractor_payments", label: "Estimaciones", notIn: ["update_menu", "cancel"]  },
            { key: "contractor_warranties", label: "Garantías", notIn: ["update_menu", "cancel"]  },
            // { key: "contractor_aditivas", label: "Aditivas", notIn: ["update_menu", "cancel"]  },
            { key: "contractor_deductivas", label: "Deductivas", notIn: ["update_menu", "cancel"]  },
           
            
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Obras - Ingresos',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
           
        },
        label: "Obras - Ingresos",
        submodules: [
            { key: "constructions_receivable_accounts", label: "Cuentas x cobrar", notIn: ["update_menu", "cancel"]  },
            { key: "constructions_estimations", label: "Estimaciones", notIn: ["update_menu", "cancel"]  },
            { key: "constructions_warranties", label: "Garantías",notIn: ["update_menu", "cancel"]  },
            // { key: "constructions_aditivas", label: "Aditivas", notIn: ["update_menu", "cancel"]  },
            { key: "constructions_deductivas", label: "Deductivas", notIn: ["update_menu", "cancel"]  }
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'ATRIBUTOS',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
           
        },
        label: "ATRIBUTOS",
        submodules: [
           
            { key: "users", label: "Usuarios", notIn: ["update_menu", "cancel"]  },
            { key: "roles", label: "roles", notIn: ["update_menu", "cancel"]  },
            
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Catalogos',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers,
                {
                    text: 'Accesos',
                    align: 'left',
                    value: 'update_menu',
                    sortable: false,
                    class: 'table-b-border  black--text'
                },
            ],
           
        },
        label: "Catalogos",
        submodules: [
            // { key: "constructions", label: "Obras", notIn: ["update_menu", "cancel"]  },
            // { key: "construction-types", label: "Tipos de obra", notIn: ["update_menu", "cancel"]  },
            { key: "business", label: "Empresas", notIn: [ "cancel"]  },
            { key: "customers", label: "Clientes", notIn: ["update_menu", "cancel"]  },
            { key: "providers", label: "Proveedores", notIn: ["update_menu", "cancel"]  },
            { key: "banks", label: "Bancos", notIn: ["update_menu", "cancel"]  },
            { key: "accounts", label: "Cuentas", notIn: ["update_menu", "cancel"]  },
            { key: "concepts", label: "Sub Cuentas", notIn: ["update_menu", "cancel"]  },
            { key: "target_accounts", label: "Sub sub cuentas", notIn: ["update_menu", "cancel"]  },
            // { key: "sub_accounts", label: "Sub cuentas", notIn: ["update_menu", "cancel"]  },
            // { key: "sub_sub_accounts", label: "Sub sub cuentas", notIn: ["update_menu", "cancel"]  },
            // { key: "auxiliaries", label: "Auxiliares", notIn: ["update_menu", "cancel"]  },
            { key: "references", label: "OT", notIn: ["update_menu", "cancel"]  },
            { key: "payment_methods", label: "Metodos de pago", notIn: ["update_menu", "cancel"]  },
            { key: "bank_accounts", label: "Cuentas bancarias", notIn: ["update_menu", "cancel"]  },
            { key: "transfers", label: "Traspasos", notIn: ["update_menu", "cancel"]  },
           
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Reportes',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
           
        },
        label: "Reportes",
        submodules: [
            { key: "balance_due_ra", label: "Saldo vencido CXC", notIn: ["update_menu", "all", "create", "update", "delete", "cancel"] },
            { key: "balance_due_pa", label: "Saldo vencido CXP", notIn: ["update_menu", "all", "create", "update", "delete", "cancel"] },
            { key: "balance_due_customers", label: "Saldo x cliente", notIn: ["update_menu", "all", "create", "update", "delete", "cancel"] },
            { key: "balance_due_providers", label : "Saldo x proveedor", notIn: ["update_menu", "all", "create", "update", "delete", "cancel"] },
            { key: "balance_paid_customers", label: "Pagos x cliente", notIn: ["update_menu", "all", "create", "update", "delete", "cancel"] },
            { key: "balance_paid_providers", label: "Pagos x proveedor", notIn: ["update_menu", "all", "create", "update", "delete", "cancel"] },
            { key: "incomes_timelapse", label: "Ingreso x periodo", notIn: ["update_menu", "all", "create", "update", "delete", "cancel"] },
            { key: "other_incomes_timerange", label: "Otros ingresos", notIn: ["update_menu", "all", "create", "update", "delete", "cancel"] },
            { key: "other_outcomes_timelapse", label: "Otros Gastos", notIn: ["update_menu", "all", "create", "update", "delete", "cancel"] },
            { key: "outcomes_timelapse", label: "Egreso x periodo", notIn: ["update_menu", "all", "create", "update", "delete", "cancel"] },
            { key: "payments_concepts", label: "Reporte x cuenta", notIn: ["update_menu", "all", "create", "update", "delete", "cancel"] },
            { key: "payments_bank_accounts", label: "Conciliación bancaria", notIn: ["update_menu", "all", "create", "update", "delete", "cancel"] },
            { key: "balance_due_ca_pa", label: "Cuentas de gastos", notIn: ["update_menu", "all", "create", "update", "delete", "cancel"] },
            
            { key: "cxc_timelapse", label: "Facturación x periodo", notIn: ["update_menu", "all", "create", "update", "delete", "cancel"]},
            { key: "cxc_customers", label: "Cobranza x Periodo", notIn: ["update_menu", "all", "create", "update", "delete", "cancel"]},
            { key: "reports_customers_payment_history", label: "Histórico pagos", notIn: ["update_menu", "all", "create", "update", "delete", "cancel"]},
            { key: "billing_receivable_accounts", label: "Facturación", notIn: ["update_menu", "all", "create", "update", "delete", "cancel"]},
            { key: "loans_pending", label: "Deuda", notIn: ["update_menu", "all", "create", "update", "delete", "cancel"]}
        ],
    },
    // {
    //     props: {
    //         headers: [
    //             {
    //                 text: 'Nómina',
    //                 align: 'left',
    //                 value: 'label',
    //                 sortable: false,
    //                 width:'40%',
    //                 class: 'table-b-border  black--text'
    //             },
    //             ...headers
    //         ],
           
    //     },
    //     label: "Permisos",
    //     submodules: [
           
    //         { key: "rrhh_paysheet", label: "Empleados", notIn:["update_menu", "create", "delete", "cancel"] },
    //         { key: "rrhh_payroll_payment", label: "Nómina", notIn:["update_menu", "cancel"] },
    //         { key: "rrhh_reports_annual_budget", label: "Presupuesto anual", notIn:["update_menu", "all", "create", "update", "delete", "cancel"] },
            
    //     ],
    // },
];

export default modules;
