<template>
    <div>
        <v-card flat color="transparent">
            <v-card-text>
                <v-row align="center">
                    <v-col
                        cols="12"
                        md="2"
                        lg="1"
                        xl="2"
                        class="mx-0 px-0 mr-md-2 py-lg-0"
                    >
                        <alternativeAutocompleteOne
                            :items="years"
                            :valueInput="selectedYear"
                            @valueChange="
                                (v) => {
                                    selectedYear = v;
                                }
                            "
                            @onChange="
                                (v) => {
                                    index();
                                }
                            "
                            :label="'Año'"
                            classesContainer="width-auto"
                        ></alternativeAutocompleteOne>
                    </v-col>

                    <v-spacer></v-spacer>
                    <v-col cols="12" md="6" lg="6" class="mx-0">
                        <v-row justify="end">
                            <iconButton
                                :props="backBtnData"
                                class="mr-0 mr-md-2 mb-3 mb-md-0"
                            ></iconButton>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="mx-0 px-0">
                        <datatable
                            :options="options"
                            :props="tableProps"
                            @methodHandler="method_handler"
                            @handleOptions="handleOptions"
                        >
                        </datatable>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import PageStateManager from "../../../helpers/PageStateManager";
import Utils from "../../../helpers/Utils";
import pdfIcon from "../../../assets/pdf_icon_2.svg";
export default {
    data() {
        return {
            bankAccount: this.$route.params.bankAccount,
            pdfIcon: pdfIcon,
            loading: true,
            ut: Utils,
            selectedYear:
                PageStateManager.getState(
                    this.$router.history.current.path,
                    "selectedYear"
                ) ?? "",
            years: [],

            serverErrors: {},
            options:
                JSON.parse(
                    PageStateManager.getState(
                        this.$router.history.current.path,
                        "optionsIndexTable"
                    )
                ) ?? {},
            backBtnData: {
                hasTooltip: true,
                tooltip: "Regresar",
                color: "primary",
                iconColor: "grey darken-3",
                height: "35",
                icon: "mdi-arrow-left",
                to: "BankAccounts",
                block: false,
                click: () => {},
            },
            actions: [
                {
                    name: "Editar",
                    icon: {
                        color: "secondary",
                        icon: "mdi-pencil-outline",
                    },
                    disabled: false,
                    type: "redirect", //  method, external, redirect
                    action: "",
                    permissions: "income_statement.show",
                },
                {
                    name: "Descargar PDF",
                    icon: {
                        custom: true,
                        color: "secondary",
                        icon: pdfIcon,
                    },
                    disabled: false,
                    type: "method", //  method, external, redirect
                    action: "openFilePdf",
                    permissions: "income_statement.show",
                },
                {
                    name: "Descargar Excel",
                    icon: {
                        color: "secondary",
                        icon: "mdi-microsoft-excel",
                    },
                    disabled: false,
                    type: "method", //  method, external, redirect
                    action: "openFileExcel",
                    permissions: "income_statement.show",
                },
            ],
            tableProps: {
                headers: [
                    {
                        text: "Mes",
                        align: "left",
                        value: "month_name",

                        class: "table-b-border black--text",
                    },
                    {
                        text: "Acciones",
                        align: "right",
                        sortable: false,
                        value: "actions",
                        width: "10%",
                        class: "table-b-border black--text",
                    },
                ],
                items: [],
                search:
                    JSON.parse(
                        PageStateManager.getState(
                            this.$router.history.current.path,
                            "optionsIndexTable"
                        )
                    )?.search ?? "",
                loading: true,
            },
        };
    },

    watch: {
        /**
         * save change
         */
        [`selectedYear`]: function () {
            // console.log(this.selectedYear, "changed")
            PageStateManager.setState(
                this.$router.history.current.path,
                "selectedYear",
                this.selectedYear
            );
        },
    },

    mounted() {
        this.getYears();
        // window.addEventListener('resize', this.onResize, { passive: true });
        // this.onResize();
        this.index();
    },

    methods: {
        onResize() {
            // if(window.innerWidth <= 960){
            //     this.exportBtnData.block = true;
            // }
            // else{
            //     this.exportBtnData.block = false;
            // }
        },
        method_handler(object) {
            // console.log(object);
            this[object.methodName](object.parameters);
        },
        handleOptions(object) {
            object.options[`search`] = this.tableProps.search;
            this.options = object.options;
            PageStateManager.setState(
                this.$router.history.current.path,
                "optionsIndexTable",
                JSON.stringify(this.options)
            );
            // this.index();
        },

        index() {
            // // console.log(this.$router)
            if (
                this.selectedYear == "" ||
                this.selectedYear == null ||
                this.selectedYear == undefined
            )
                return;

            this.tableProps.loading = true;

            //call api
            this.$api.report
                .reportIncomeStatements(
                    this.$store.state.business,
                    this.bankAccount,
                    this.selectedYear
                )
                .then((resp) => {
                    this.tableProps.items = resp.data.map((x) => {
                        x["actions"] = JSON.parse(JSON.stringify(this.actions));

                        // x.actions[0].action = { name: '', params: { slug: x.slug } }
                        x.actions[0].action = {
                            name: "BankAccountsReportIncomeStatementsEdit",
                            params: {
                                bankAccount: this.bankAccount,
                                month: x.month,
                                year: this.selectedYear,
                            },
                        };

                        if (x.pdf_file_url == null || x.pdf_file_url == "") {
                            x.actions[1].disabled = true;
                        }
                        if (
                            x.excel_file_url == null ||
                            x.excel_file_url == ""
                        ) {
                            x.actions[2].disabled = true;
                        }

                        x["parameters"] = {
                            id: x.id,
                            name: x.month,
                            pdf_file_url: x.pdf_file_url,
                            excel_file_url: x.excel_file_url,
                        };

                        return x;
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.tableProps.loading = false;
                });
        },

        getYears() {
            const yearsData = Utils.getYearsSelects(this.selectedYear);
            this.selectedYear = yearsData.selectedYear;
            this.years = yearsData.years;
        },
        openFilePdf(params) {
            this.openFile(params.pdf_file_url);
        },
        openFileExcel(params) {
            this.openFile(params.excel_file_url);
        },
        openFile(url) {
            if (url != null && url != "");
            window.open(url);
        },
    },
};
</script>


<style lang="scss">
</style>