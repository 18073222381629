<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="3" lg="3" class="mx-0 px-0">
						<!-- <v-text-field v-model="tableProps.search" label="Buscar un banco" clearable prepend-inner-icon="mdi-magnify" hide-details></v-text-field> -->
                        <alternativeTextField2
                            @onChanged="(v) => {trySearch(v);}" 
                            @onClear="(v) => {trySearch(v);}" 
                            :valueInput="tableProps.search"
                            :rule="[]"
                            :placeholder="'Buscar'"
                            :label="''"
                            :clearable="true"
                            :prependInnerIcon="'mdi-magnify'"
                            class="pick-3"
                        ></alternativeTextField2>
					</v-col>
					<v-spacer></v-spacer>
					<v-col  v-permission="'payable_accounts.create'" cols="12" md="3" lg="2" class="mx-0 px-0 text-right">
                        <iconButton :props="createBtnData"></iconButton>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0">
						<datatablePagination :options="options" :total="total"  :props="tableProps" @methodHandler="method_handler" @handleOptions="handleOptions">
                           
                        </datatablePagination>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

        <modal :props="modalProps" @modalResponse="method_handler">
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end">
					<v-col cols="12" sm="4">
						<secondaryButton
						:props="cancelModal"
						></secondaryButton>
					</v-col>
					<v-col cols="12" sm="4">
						<primaryButton
						:props="acceptModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>
		
	</div>
</template>
<script>
import Utils from '../../helpers/Utils';
import PageStateManager from '../../helpers/PageStateManager';
export default {
    data(){
		return {
            options: (JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable')))??{},
            total: 10,
			search: '',
            selectedID: '',
			actions: [
                {
					name: 'Adjunto',
					icon: {
						color: 'secondary',
						icon: 'mdi-file-document-outline'
					},
					disabled: true,
					type: 'external',   //  method, external, redirect
					action: '',
                    permissions: 'payable_accounts.show'
				},
                {
					name: 'Anticipos',
					icon: {
						color: 'secondary',
						icon: 'mdi-cards-outline'
					},
					disabled: true,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'payable_accounts.show'
				},
				{
					name: 'Detalle',
					icon: {
						color: 'secondary',
						icon: 'mdi-information-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'payable_accounts.show'
				},
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/obras/1/edicion',
                    permissions: 'payable_accounts.update'
				},
                {
					name: 'Cancelar/restaurar',
					icon: {
						color: 'secondary',
						icon: 'mdi-cancel'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'cancelItemModal',
                    permissions: 'payable_accounts.cancel'
				},
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItemModal',
                    permissions: 'payable_accounts.delete'
				},
			],
			tableProps: {
				headers: [
					{
						text: 'Folio',
						align: 'left break-words',
						value: 'folio',
						width: '9%',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Cuenta',
						align: 'left',
						value: 'account_name',
						class: 'table-b-border black--text'
					},
                    {
						text: 'VIGENCIA',
						align: 'left',
						value: 'due_date',
                        width: '5%',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Sub Cuenta',
						align: 'left',
                        width: '5%',
						value: 'concept_name',
						class: 'table-b-border black--text'
					},
                    {
						text: 'PROVEEDOR',
						align: 'left',
						value: 'provider_name',
                        
						class: 'table-b-border black--text'
					},
                     {
						text: 'MONTO',
						align: 'left',
						value: 'balance',
                        
						class: 'table-b-border black--text'
					},
                     {
						text: 'ADEUDO',
						align: 'left',
						value: 'total',
                        
						class: 'table-b-border black--text'
					},
                     {
						text: 'ESTATUS',
						align: 'left',
						value: 'status',
                        width: '5%',
						class: 'table-b-border black--text'
					},
					{
						text: 'ACCIONES',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: (JSON.parse(PageStateManager.getState(this.$router.history.current.path, 'optionsIndexTable')))?.search??"",
				loading: false,
                sortBy: 'created_at',
                sortDesc: true,
			},
			
			
            createBtnData: {
                hasTooltip:true,
                tooltip: 'Nueva Compra',
                color:'primary',
                iconColor:'grey darken-3',
                height:'35',
                icon: "mdi-plus",
				to: "CXPCreate",
				block: true,
                click: ()=>{}
            },

			modalProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},
			
			cancelModal: {
                text: "No, cancelar",
                icon: "",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.modalProps.visible = false}
            },
			acceptModal: {
                text: "SI, ELIMINAR",
                icon: "",
				to: "",
				block: true,
				color: 'error',
				textColor:'white',
                textColorLevel: '0',
				loading:false,
                click: ()=>{this.delete()}
            },
		}
	},

	mounted(){
		
	},

    methods: {
        method_handler(object){
            console.log(object);
			this[object.methodName](object.parameters)
		},
        handleOptions(object){
            object.options[`search`] = this.tableProps.search;
			//console.log(object.options);
			this.options = object.options;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
			this.index();
		},
        trySearch(val){
            this.tableProps.search = val;
            this.options.search = val;
            this.options.page = 1;
            PageStateManager.setState(this.$router.history.current.path, 'optionsIndexTable', JSON.stringify(this.options));
            this.index();
        },
		index() {
			this.tableProps.loading = true;
            this.options['search'] = this.tableProps.search;
			let filter = Utils.createFilter(this.options, this.tableProps.headers);
			//call api
			this.$api.payableAccount.list(this.$store.state.business, filter)
				.then((resp) =>{
					//console.log(resp);
					this.tableProps.items = resp.data.data.map((x) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actions));
                        if(x.invoice_pdf_path != null && x.invoice_pdf_path != ''){
                            x.actions[0].action = x.invoice_pdf_path;
                            x.actions[0].disabled = false;
                        }
                        if(x.type == 'ANTICIPADA')
                            x.actions[1].disabled = false;
						x.actions[1].action = { name: 'AdvancedCXPIndex', params: { slug: x.slug } }
						x.actions[2].action = { name: 'CXPDetails', params: { slug: x.slug } }
                        x.actions[3].action = { name: 'CXPEdit', params: { slug: x.slug } }
						

						//x.status = 'cancelada';
						x['parameters'] = { 
                            id: x.slug, 
                            name: x.folio,
                            modalTitle: x.status == 'cancelada' 
                                ? 'DESHACER CANCELACIÓN' 
                                : 'CANCELAR COMPRA',
                            modalMesage: x.status == 'cancelada' 
                                ? `ESTAS A PUNTO DE DESHACER LA CANCELACIÓN DE ESTA COMPRA, ESTO LA REGRESARÁ A SU ESTADO PREVIO Y RESTAURARÁ LA DEUDA CORRESPONDIENTE ¿DESEAS CONTINUAR?` 
                                : `ESTAS A PUNTO DE CANCELAR ESTA COMPRA, AL HACERLO CAMBIARÁ SU ESTATUS Y NO SERÁ POSIBLE REGISTRAR PAGOS EN ELLA ¿DESEAS CONTINUAR?`,
                            modalAcceptBtnText: x.status == 'cancelada' 
                                ? 'SI, DESHACER' 
                                : 'SI, CANCELAR',
                            modalColor: x.status == 'cancelada' 
                                ? 'success' 
                                : 'error',
                            modalAcction: x.status == 'cancelada'
                                ? this.restoreCXC
                                : this.cancelCXC,
                        };

                        let original = JSON.parse(JSON.stringify(x));
						//x.status = x.status == true ? 'Activo' : 'Inactivo';
                        x.due_date = Utils.globalDateFormat(x.due_date);
                        x.balance = Utils.currencyFormat(original.total, x.currency);
                        x.total = Utils.currencyFormat((original.total - original.balance), x.currency);
						return x
					});
                    this.total = resp.data.total
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},

		deleteItemModal(parameters)
		{
			this.acceptModal.text = "SI, ELIMINAR";
            this.cancelModal.text = "No, cancelar";
            this.acceptModal.click = this.delete;
            this.acceptModal.color = 'error';
			this.selectedID = parameters.id;
			this.modalProps = {
				...this.modalProps,
				text: `¿Estás a punto de eliminar una compra, una vez realizada esta acción es irreversible ¿deseas continuar con la eliminación de todos modos?`,
                title: 'Eliminar',
                bottomLineColor: 'error',
			}
            console.log(this.selectedID)
			this.modalProps.visible = true;
			
		},


		delete()
		{
			//console.log(this.selectedID);
			this.acceptModal.loading = true;
			this.$api.payableAccount.delete(this.$store.state.business,this.selectedID)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', `Se ha eliminado`);
				this.index()
				// eslint-disable-next-line
			}).catch(error => {
				if(error.response.status === 422){
                    this.$store.dispatch('snackbarError', `${error.response.data.error??'Error al eliminar'}`);
                }else{
                    this.$store.dispatch('snackbarError', `Error al eliminar. Intenta de nuevo`);
                }

				
			})
			.finally(() => {
				this.modalProps.visible = false;
				this.acceptModal.loading = false;
			})
		},

        cancelItemModal(parameters)
        {
            this.acceptModal.text = parameters.modalAcceptBtnText;
            this.cancelModal.text = "No";
            this.acceptModal.click = parameters.modalAcction;
            this.acceptModal.color = parameters.modalColor;
            this.selectedID = parameters.id;
            this.modalProps = {
                ...this.modalProps,
                title: parameters.modalTitle,
                text: parameters.modalMesage,
                bottomLineColor: parameters.modalColor,
            };
            this.modalProps.visible = true;
        },

        cancelCXC()
        {
            this.updateStatus('Se ha cancelado correctamente');
        },

        restoreCXC()
        {
            this.updateStatus('Se ha restaurado correctamente');
        },

        updateStatus(message)
        {
            this.acceptModal.loading = true;
			this.$api.payableAccount.updateStatus(this.$store.state.business,this.selectedID)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', message);
				this.index()
				// eslint-disable-next-line
			}).catch(error => {
				if(error.response.status === 422){
                    this.$store.dispatch('snackbarError', `${error.response.data.error??'Error al modificar'}`);
                }else{
                    this.$store.dispatch('snackbarError', `Error. Intenta de nuevo`);
                }

				
			})
			.finally(() => {
				this.modalProps.visible = false;
				this.acceptModal.loading = false;
			})
        }


    }
}
</script>